import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, timer } from 'rxjs';
import { catchError, first, timeout } from 'rxjs/operators';
import { IpcService } from '@services/ipc/ipc.service';
import { MediaDevicesService } from '../media-devices/media-devices.service';
import { SessionService } from '../session/session.service';
import { VideoService } from '../video/video.service';
import { LoggerService } from '../logger/logger.service';
import { IntmVideoService } from '../intmvideo/intmvideo.service';
import { IVideoCallInputCommand } from '@/shared/interfaces/api/video-call-input.interface';
import { Store } from '@ngrx/store';
import { closeActiveToast } from '@/shared/storage/layout/layout.actions';

@Injectable({
    providedIn: 'root',
})
export class ReceiveCallService {
    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private _store: Store,
        private _sessionService: SessionService,
        private _ipcService: IpcService,
        private _loggerService: LoggerService,
        private _videoService: VideoService,
        private _mediaDevicesService: MediaDevicesService,
        private _intmnVideoService: IntmVideoService
    ) {}

    setCameraConfigOnReceiveCall() {
        const input = {
            w: 100,
            h: 100,
            command: 'home',
            device_id: this._sessionService.deviceId,
        };

        this._httpClient
            .post(
                this._sessionService.getConfig().fleet_url +
                    'v1/apgar/camera/pan_tilt_command',
                input,
                {
                    headers: {
                        Authorization: this._sessionService.accessToken,
                    },
                    responseType: 'json',
                }
            )
            .pipe(
                timeout(this._sessionService.enableFleetTimeout ? 5000 : 70000),
                catchError((error) => {
                    let errorMsg;
                    if (error.error instanceof ErrorEvent) {
                        errorMsg = `Error: ${error.error.message}`;
                    } else {
                        errorMsg = `Error: ${error.message}`;
                    }
                    this._loggerService.error('fleet:sendCommand', errorMsg);
                    return of([]);
                })
            )
            .subscribe();
    }

    receiveCall(fleetCommand: IVideoCallInputCommand) {
        this._loggerService.info('receivedCall', fleetCommand);
        const deviceInstance = this._sessionService.deviceInstance;
        /** Ignore calls when Cart is already in a room. This means: */
        /** 1. Ignoring calls when a second provider calls the same case. and the cart is on a call. */
        /** 2. Ignoring calls when a user calls the same cart from Connect.*/
        if (this._videoService.getRoom() && deviceInstance.onCall) {
            return;
        }
        // Get ride of all dialog to make sure that the user wont be able to go back to the previous call(s) if any
        this._store.dispatch(closeActiveToast());
        deviceInstance.onCall = true;
        this._ipcService.setDisplayTurnOn();
        this.setCameraConfigOnReceiveCall();
        this._videoService.setRoom({
            data: fleetCommand.parameter,
        });
        const defaultAudioOutput =
            this._mediaDevicesService.getBuiltInAudioOutput();

        if (defaultAudioOutput) {
            const msg = {
                command: SYSTEM_COMMANDS_TYPES.CHANGE_AUDIO_GAIN,
                volume: this._sessionService.getConfig().RingtoneDefaultVolume,
                vendorId: defaultAudioOutput.vendorId,
                productId: defaultAudioOutput.productId,
            };
            deviceInstance.setSpeakerGain(msg);
        }
        timer(3000).subscribe(() => {
            this._router.navigateByUrl('/video/incoming');
        });
    }

    receiveObserverCall(fleetCommand: IVideoCallInputCommand) {
        this._loggerService.info('receiveObserverCall', fleetCommand);
        if (this._intmnVideoService.getRoom()) {
            return;
        }
        const deviceInstance = this._sessionService.deviceInstance;
        deviceInstance.onCall = true;
        try {
            const audioInput = deviceInstance.getDefaultAudioInput();
            const audioOutput = deviceInstance.getDefaultAudioOutput();
            this._ipcService.enableEchoCancel(audioInput, audioOutput);
        } catch (err) {
            this._loggerService.error('receiveObserverCall', err);
        }

        this._ipcService.setLEDTurnOn('BLUE');

        timer(3000).subscribe(() => {
            deviceInstance.setMicGain({});
            const ringToneVolume =
                this._sessionService.getConfig().RingtoneDefaultVolume !==
                undefined
                    ? this._sessionService.getConfig().RingtoneDefaultVolume
                    : 20;
            deviceInstance.setSpeakerGain({ volume: ringToneVolume });
        });

        this._intmnVideoService.startObserverCall(fleetCommand.parameter);
    }

    receiveInteractiveCall(fleetCommand: IVideoCallInputCommand) {
        this._loggerService.info('receiveInteractiveCall', fleetCommand);
        const deviceInstance = this._sessionService.deviceInstance;
        deviceInstance.onCall = true;

        /** This is to make call work on local envs */
        this.makeReceiveInteractiveCallWorkOnLocalEnv(deviceInstance);

        if (
            deviceInstance.deviceStatus &&
            deviceInstance.deviceStatus.power !== 'true'
        ) {
            deviceInstance.turnOffOnDisconnect = true;
            this._ipcService
                .requestTVTurnOn()
                .pipe(first())
                .subscribe(() => {
                    timer(8000).subscribe(() => {
                        this.requestSwitchToPrimarySource();
                    });
                });
        } else {
            this.requestSwitchToPrimarySource();
        }
    }

    requestSwitchToPrimarySource() {
        this._ipcService
            .requestSwitchToPrimarySource()
            .pipe(first())
            .subscribe(() => {
                this._router.navigateByUrl('/tvkit-video/intm-incoming');
            });
    }

    makeReceiveInteractiveCallWorkOnLocalEnv(deviceInstance) {
        if (this._router.url.indexOf('localhost') > -1) {
            deviceInstance.deviceStatus = {
                attached: 'true',
                power: 'true',
            };
        }
    }
}
