import { of, Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    catchError,
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    map,
} from 'rxjs/operators';
import {
    IBatteryStatus,
    IEndpointConfiguration,
    IEndpointSettings,
    IMediaDevices,
    INetwork,
    INetworkDevice,
    INetworkListResponse,
    IWaitingRoomState,
} from '@shared/interfaces';

// import * as configStorage from '@storage/endpoint-config';
// import * as networkStorage from '@storage/network';
import * as endpointStorage from '@storage/endpoint';
import * as authReducer from '@storage/auth/auth.reducer';
import * as waitingRoomAction from '@storage/waiting-room/waiting-room-actions';

import {
    MediaDevicesActions,
    updateMediaDevices,
} from '@storage/media-devices/media-devices.actions';

import * as EndpointConfigActions from '@/shared/storage/endpoint-config/endpoint-config.actions';
import * as NetworkActions from '@storage/network/network.actions';
import * as BatteryActions from '@storage/battery/battery.actions';
import * as DeviceActions from '@storage/device/device-actions';
import * as AuthActions from '@storage/auth/auth.actions';
import { switchCameraAction } from '@/shared/storage/video-info';

import { IDeviceInfo } from '@interfaces/device/device-info.interface';

import { IServiceLine } from '@interfaces/service-line/service-line.interface';

import { LoggerService } from '../logger';

import * as fromAppReducer from '../../../app.reducer';

@Injectable({ providedIn: 'root' })
export class StorageService {
    private batteryType: 'RIPPLE' | 'PANDORA';

    constructor(
        private _authStore: Store<{ auth: authReducer.IAuthState }>,
        private _batteryStore: Store<{ battery: IBatteryStatus }>,
        private _configStore: Store<{ config: IEndpointConfiguration }>,
        private _deviceInfoStore: Store<{ device: IDeviceInfo }>,
        private _mediaDevicesStore: Store<{ mediaDevices: IMediaDevices }>,
        private _networkStore: Store<{ network: INetworkListResponse }>,
        private _loggerService: LoggerService,
        private _waitingRoomStore: Store<{ waitingRoom: IWaitingRoomState }>,
        private _appStore: Store<fromAppReducer.AppState>,
        private _store: Store
    ) {}

    /* Config Storage Functionalty */

    updateEndpointConfig(key: string, value: any, skipSettings = true): void {
        this._appStore.dispatch(
            EndpointConfigActions.updateEndpointConfig({
                key,
                value,
                skipSettings,
            })
        );
    }

    switchCamera(VideoInput: string): void {
        this._store.dispatch(switchCameraAction({ switchCamera: VideoInput }));
    }

    updateMediaDevices(skipAxisCamera: boolean = false): void {
        this._mediaDevicesStore.dispatch(updateMediaDevices({ skipAxisCamera }));
    }

    // refreshConfig(): void {
    //     this._configStore.dispatch(configStorage.getEndpointConfig());
    // }

    setEndpoint(endpoint: IEndpointSettings): void {
        this._configStore.dispatch(endpointStorage.setEndpoint(endpoint));
    }

    // setConfig(updatedConfig: IEndpointConfiguration): void {
    //     this._configStore.dispatch(
    //         configStorage.reloadEndpointConfig({
    //             endpointConfig: updatedConfig,
    //         })
    //     );
    // }
    refreshEndpointConfig(): void {
        this._appStore.dispatch(EndpointConfigActions.getEndpointConfig());
    }

    setEndpointConfig(updatedConfig: IEndpointConfiguration): void {
        this._appStore.dispatch(
            EndpointConfigActions.reloadEndpointConfig({
                endpointConfig: updatedConfig,
            })
        );
    }

    subscribeOnRingtoneVolumeChange(callback): Subscription {
        return this._appStore
            .select('endpointConfig')
            .pipe(filter((state) => state !== null))
            .pipe(distinctUntilKeyChanged('RingtoneDefaultVolume'))
            .pipe(map((config) => config.RingtoneDefaultVolume))
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    subscribeOnConfigChange(callback): Observable<unknown> | Subscription {
        if (!callback) {
            return this._appStore
                .select('endpointConfig')
                .pipe(filter((state) => state !== null))
                .pipe(distinctUntilChanged()) // subscribe only if state changed
                .pipe(catchError(this.handleError));
        }
        return this._appStore
            .select('endpointConfig')
            .pipe(filter((state) => state !== null))
            .pipe(distinctUntilChanged()) // subscribe only if state changed
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    subscribeOnUpdateTypeChange(callback): Subscription {
        return this._appStore
            .select('endpointConfig')
            .pipe(filter((state) => state !== null))
            .pipe(distinctUntilKeyChanged('update_type'))
            .pipe(map((config) => config.update_type))
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    /* Auth Storage Functionalty */
    subscribeOnAuthChange(callback): Observable<unknown> | Subscription {
        if (!callback) {
            return this._appStore
                .select('auth')
                .pipe(filter((state) => state !== null))
                .pipe(distinctUntilChanged()) // subscribe only if state changed
                .pipe(catchError(this.handleError));
        }
        return this._appStore
            .select('auth')
            .pipe(filter((state) => state !== null))
            .pipe(distinctUntilChanged()) // subscribe only if state changed
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    subscribeOnAuthIsAuthenticated(callback): Observable<any> | Subscription {
        if (!callback) {
            return this._appStore
                .select('auth')
                .pipe(filter((authState) => authState !== null))
                .pipe(distinctUntilKeyChanged('isAuthenticated'))
                .pipe(map((authState) => authState.isAuthenticated))
                .pipe(catchError(this.handleError));
        }

        return this._appStore
            .select('auth')
            .pipe(filter((authState) => authState !== null))
            .pipe(distinctUntilKeyChanged('isAuthenticated'))
            .pipe(map((authState) => authState.isAuthenticated))
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    subscribeOnAuthIsWebsocketConnected(
        callback
    ): Observable<any> | Subscription {
        if (!callback) {
            return this._appStore
                .select('auth')
                .pipe(filter((authState) => authState !== null))
                .pipe(distinctUntilKeyChanged('isWebsocketConnected'))
                .pipe(map((authState) => authState.isWebsocketConnected))
                .pipe(catchError(this.handleError));
        }

        return this._appStore
            .select('auth')
            .pipe(filter((authState) => authState !== null))
            .pipe(distinctUntilKeyChanged('isWebsocketConnected'))
            .pipe(map((authState) => authState.isWebsocketConnected))
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    subscribeOnAuthIsInternetConnected(
        callback
    ): Observable<any> | Subscription {
        if (!callback) {
            return this._appStore
                .select('auth')
                .pipe(filter((authState) => authState !== null))
                .pipe(distinctUntilKeyChanged('isInternetConnected'))
                .pipe(map((authState) => authState.isInternetConnected))
                .pipe(catchError(this.handleError));
        }

        return this._appStore
            .select('auth')
            .pipe(filter((authState) => authState !== null))
            .pipe(distinctUntilKeyChanged('isInternetConnected'))
            .pipe(map((authState) => authState.isInternetConnected))
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    authenticate(): void {
        this._appStore.dispatch(AuthActions.authenticate());
    }

    logout(): void {
        this._appStore.dispatch(AuthActions.authFailure());
    }

    /* Network Storage Functionalty */

    subscribeOnNetworkChange(callback): Subscription {
        return this._appStore
            .select('network')
            .pipe(filter((state) => state !== null))
            .pipe(distinctUntilChanged()) // subscribe only if state changed
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    refreshNetworkDevice(
        networkDevices: INetworkDevice[],
        activeWifiNetwork: INetwork | {}
    ): void {
        this._appStore.dispatch(
            NetworkActions.refreshNetworkStatus({
                device_list: networkDevices,
                active_wifi_network: activeWifiNetwork,
            })
        );
    }

    refreshNetworkList(
        networkDevices: INetworkDevice[],
        networkList: INetwork[]
    ): void {
        this._appStore.dispatch(
            NetworkActions.refreshNetworkList({
                device_list: networkDevices,
                network_list: networkList,
            })
        );
    }

    /* Battery Storage Functionalty */
    subscribeOnBatteryStatusChange(callback): Subscription {
        return this._appStore
            .select('battery')
            .pipe(filter((state) => state !== null))
            .pipe(distinctUntilChanged()) // subscribe only if state changed
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    setBatteryType(batteryType: 'RIPPLE' | 'PANDORA') {
        this.batteryType = batteryType;
    }

    getBatteryType() {
        return this.batteryType;
    }

    updateBatteryStatus(batteryStatus: IBatteryStatus): void {
        this._appStore.dispatch(
            BatteryActions.updateBatteryStatus({ batteryStatus })
        );
    }
    /* Device Info Functionality */

    updateDeviceInfo(deviceInfo: IDeviceInfo): void {
        this._appStore.dispatch(DeviceActions.updateDeviceInfo({ deviceInfo }));
    }

    updateDeviceInfoByKey(key: string, value: string): void {
        this._appStore.dispatch(
            DeviceActions.updateDeviceInfoByKey({ key, value })
        );
    }

    changeAudioGain(message: {
        volume: number;
        vendorId: string | number;
        productId: string | number;
    }): void {
        // TODO: function of changing Audio Output (old one changeAudioGain from ObservableService)
        const msg = {
            command: 'CHANGE_AUDIO_GAIN',
            ...message,
        };
        // eslint-disable-next-line no-console
        console.log(msg);
    }

    changeMicGain(message: {
        vendorId: string | number;
        productId: string | number;
    }): void {
        // TODO: function of changing Audio Input (old one changeMicGain from ObservableService)
        const msg = {
            command: 'CHANGE_MIC_GAIN',
            ...message,
        };
        // eslint-disable-next-line no-console
        console.log(msg);
    }

    notifyAudioVideoChanges(): void {
        // TODO: function of changing Audio Video (old one changeMicGain from ObservableService)
    }

    // subscribeHeadphoneDetect(): Observable<any> {
    //     // TODO: function of changing Audio Video (old one subscribeHeadphoneDetect from ObservableService)
    //     return of();
    // }

    // subscribeHeadsetMicDetect(): Observable<any> {
    //     // TODO: function of changing Audio Video (old one subscribeHeadphoneDetect from ObservableService)
    //     return of();
    // }

    // subscribeStethLineInDetect(): Observable<any> {
    //     // TODO: function of changing Audio Video (old one subscribeHeadphoneDetect from ObservableService)
    //     return of();
    // }

    subscribeOnDeviceInfo(callback): Subscription {
        return this._appStore
            .select('device')
            .pipe(filter((state) => state !== null))
            .pipe(distinctUntilChanged())
            .pipe(catchError(this.handleError))
            .subscribe(callback);
    }

    /* Waiting Room */

    setIntakeData(intakeData: any): void {
        this._waitingRoomStore.dispatch(
            waitingRoomAction.setIntakeData({ intakeData })
        );
    }

    setServiceLine(serviceLine: IServiceLine): void {
        this._waitingRoomStore.dispatch(
            waitingRoomAction.setServiceLine({ serviceLine })
        );
    }

    setGenderOptions(genderOptions): void {
        this._waitingRoomStore.dispatch(
            waitingRoomAction.setGenderOptions({ genderOptions })
        );
    }

    setProviderId(providerId): void {
        this._waitingRoomStore.dispatch(
            waitingRoomAction.setProviderId({ providerId })
        );
    }

    updateServiceLine(serviceLine: IServiceLine): void {
        this._waitingRoomStore.dispatch(
            waitingRoomAction.updateServiceLine({ serviceLine })
        );
    }

    updateIntakeData(intakeData): void {
        this._waitingRoomStore.dispatch(
            waitingRoomAction.updateIntakeData({ intakeData })
        );
    }

    setPractise(practise): void {
        this._waitingRoomStore.dispatch(
            waitingRoomAction.setPractise({ practise })
        );
    }

    /* Media Devices */

    setAllowStethRecording(isAllow: boolean): void {
        this._mediaDevicesStore.dispatch(
            MediaDevicesActions.setAllowStethRecording({ isAllow })
        );
    }

    setAllowSnapshots(isAllow: boolean): void {
        this._mediaDevicesStore.dispatch(
            MediaDevicesActions.setAllowSnapshots({ isAllow })
        );
    }

    setIsUploadingFromDevice(isUploading: boolean): void {
        this._mediaDevicesStore.dispatch(
            MediaDevicesActions.setIsUploadingFromDevice({ isUploading })
        );
    }

    handleError(error): Observable<any> {
        this._loggerService.error('StorageService', error);
        return of({});
    }
}
